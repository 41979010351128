import React, { useState, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { useOnClickOutside, useIsMobile, useFiltersCleared } from 'hooks/index';
import { themr } from '@friendsofreactjs/react-css-themr';

import SelectDown from 'images/icons/select-arrow.svg';

interface Props {
  callback: Function;
  children?: React.ReactChild | React.ReactChild[];
  defaultValue?: string | number;
  filter?: boolean;
  length?: number;
  name: string;
  options: Array<string | number>;
  placeholder: string;
  required?: boolean;
  theme?: Theme;
}

import styles from './styles.scss';

const Select = ({ callback, children, defaultValue, filter, length, name, options, placeholder, required, theme }: Props) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(defaultValue || '');
  const [isMobile] = useIsMobile(768);
  const ref = useRef(null);
  useFiltersCleared(() => filter && setValue(''));

  useEffectOnce(() => {
    if(defaultValue && defaultValue !== value) {
      setValue(defaultValue);
    }
  });

  function toggleActive() {
    setActive(!active);
  }

  useOnClickOutside(ref, () => {
    if (active) {
      toggleActive();
    }
  });

  function handleChange(value: string | number) {
    setValue(value.toString());
    callback(name, value);
  }

  return (
    isMobile ? (
      <div className={theme!.selectContainer}>
        <span>
          {children}
        </span>
        <select
          className={theme!.selectField}
          onChange={(e) => handleChange(e.target.value)}
          value={value || "placeholder"}
          required={required}
        >
          {placeholder && <option disabled value="placeholder">{placeholder}</option>}
          {options.map((v: string | number) => (
            <option
              key={v}
              value={v.toString()}
            >
              {v}
            </option>
          ))}
        </select>
        <span className={theme!.down}>
          <SelectDown />
        </span>
      </div>
    ) : (
      <span>
        <div
          className={theme!.selectField}
          onClick={toggleActive}
          onKeyPress={toggleActive}
          data-placeholder={!value}
          ref={ref}
          tabIndex={0}
        >
          {children}
          <span data-value>
            {value || placeholder}
          </span>
          <ul data-active={active} style={{ height: `${(active && length) ? `${length}px` : ''}`}}>
            {options.map((v: string | number) => (
              <li
                onClick={() => handleChange(v)}
                onKeyPress={() => handleChange(v)}
                key={v}
                id={v.toString()}
                tabIndex={0}
              >
                {v}
              </li>
            ))}
          </ul>
          <span className={theme!.down}>
            <SelectDown />
          </span>
        </div>
      </span>
    )
  );
};

export default themr('Select', styles)(Select);
